<template>
  <div class="video-container">
    <router-view />
  </div>
</template>

<script>
import video from '@/store/video'
import Test from './Test'
export default {
  components: {
    // Test
  }
  ,
  created () {
    if (this.$store._modules.root._children.video === undefined) {
      this.$store.registerModule('video', video)
    }
  }
}
</script>
