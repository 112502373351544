var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Test widget ")]), _c('Row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('Col', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "type": "ONE_VALUE",
      "force-endpoint": "http://localhost:8000/testValue"
    }
  })], 1), _c('Col', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "type": "CHART",
      "force-endpoint": "http://localhost:8000/testPie"
    }
  })], 1), _c('Col', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "type": "CHART",
      "force-endpoint": "http://localhost:8000/testLine"
    }
  })], 1), _c('Col', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "type": "CHART",
      "force-endpoint": "http://localhost:8000/testBar"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }