<template>
  <div>
    <h1>Test widget </h1>
    <Row :gutter="24">
      <Col span="12"
           style="margin-bottom:12px"
      >
        <ApiWidgetFactory
          type="ONE_VALUE"
          force-endpoint="http://localhost:8000/testValue"
        />
      </Col>
      <Col span="12"
           style="margin-bottom:12px"
      >
        <ApiWidgetFactory
          type="CHART"
          force-endpoint="http://localhost:8000/testPie"
        />
      </Col>
      <Col span="12"
           style="margin-bottom:12px"
      >
        <ApiWidgetFactory
          type="CHART"
          force-endpoint="http://localhost:8000/testLine"
        />
      </Col>
      <Col span="12"
           style="margin-bottom:12px"
      >
        <ApiWidgetFactory
          type="CHART"
          force-endpoint="http://localhost:8000/testBar"
        />
      </Col>
    </Row>
  </div>
</template>
<script>
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'

export default {
  name: 'TestWidget',
  components: {ApiWidgetFactory},
  data () {
    return {}
  }
}
</script>
<style scoped>

</style>